import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import banner1 from '../../../images/pages/bpm/banner2.jpg'
import banner2 from '../../../images/pages/bpm/banner3.png'
import {
  faCogs,
  faProjectDiagram,
  faLayerGroup,
  faServer,
} from '@fortawesome/free-solid-svg-icons'

const slides = [
  {
    title: <>GESTÃO POR PROCESSOS DE NEGÓCIO BPM</>,
    description: (
      <>
        Eleve a performance do seu negócio por meio da Gestão por Processos BPM
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: <>GESTÃO POR PROCESSOS DE NEGÓCIO BPM</>,
    description: (
      <>
        Transforme seus resultados de forma ágil, com visão ponta a ponta e de
        forma descomplicada
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `P4 Engenharia`,
    text: `Tarefas repetitivas passam a ser executadas por Robos`,
    quantity: 74,
  },
  {
    title: `SASCAR`,
    text: `Aderências ao sistema Totvs`,
    quantity: 32,
  },
  {
    title: `UNIMED Rio Preto`,
    text: `Projeto de transformção digital e redução de atividades manuais`,
    quantity: 96,
  },
  {
    title: `Okubo`,
    text: `Estruturação da área comercial e aumento da aderência aos sistemas da empresa`,
    quantity: 40,
  },
]

const Bpm: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faCogs,
      title: `Mapeamento e Melhoria de Processos`,
      description: (
        <>
          Atuamos com base nas boas práticas de mercado, começando pelo
          mapeamento de processo AS IS, sugerimos melhorias, desenhamos o
          processo TO BE e implantamos as mudanças.
        </>
      ),
      externalLink: {
        url: `/solucoes/gestao-por-processos-de-negocio-bpm/mapeamento-e-melhoria-de-processos/`,
        sameOrigin: true,
        text: `Saiba mais`,
      },
    },
    {
      faIcon: faProjectDiagram,
      title: `Escritório de Processos`,
      description: (
        <>
          Fazemos a implantação do Escritório de Processos, responsável por
          apoiar toda a organização com metodologias, técnicas, ferramentas, e
          treinamento em gestão por processos, desde o nível operacional ao
          estratégico.
        </>
      ),
      externalLink: {
        url: `/solucoes/gestao-por-processos-de-negocio-bpm/escritorio-de-processos/`,
        sameOrigin: true,
        text: `Saiba mais`,
      },
    },
    {
      faIcon: faLayerGroup,
      title: `Treinamento BPM`,
      description: (
        <>
          Conceitos BBPM baseados no CBOK 4.0, boas práticas e frameworks de
          processos. Entenda o modelo organizacional para identificar, desenhar,
          executar, documentar, medir, monitorar, controlar e melhorar processos
          de negócio.
        </>
      ),
      externalLink: {
        url: `https://blog.valoremelhoria.com.br/2021-07-22-forma%C3%A7%C3%A3o-de-analista-de-processos-gerenciamento-de-processos-de-neg%C3%B3cios-bpm/`,
        text: `Veja aqui a ementa`,
      },
    },
    {
      faIcon: faServer,
      title: `Aderência de Sistemas`,
      description: (
        <>
          Apoiamos na escolha do melhor sistema de gestão (ERP ou CRM) para o
          seu negócio. Avaliamos a aderência do sistema aos processos, relação
          custo-benefício, provas de conceito. Seja assertivo na tomada de
          decisão para migração ou aquisição de novo sistema.
        </>
      ),
      externalLink: {
        url: `/solucoes/gestao-por-processos-de-negocio-bpm/aderencia-de-sistemas/`,
        text: `Saiba mais`,
        sameOrigin: true,
      },
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Planejamento',
      description: (
        <>
          A fase do planejamento é um processo comercial. Neste momento ainda
          não houve a contratação do produto. O objetivo desta etapa é alinhar
          todas as expectativas do projeto e os objetivos junto à contratante.
          <AnchorLink to={`${location.pathname}#contato`}>
            {' '}
            Agende aqui essa etapa sem compromisso.
          </AnchorLink>
        </>
      ),
    },
    {
      count: 2,
      title: 'Mapeamento',
      description: (
        <>
          A etapa do mapeamento do processo é o momento em que identificamos as
          dores dos colaboradores da empresa cliente, quanto às atividades do
          dia a dia que levaram o processo a chegar à estrutura atual.
        </>
      ),
    },
    {
      count: 3,
      title: 'Análise e Melhoria',
      description: (
        <>
          Nesta etapa iremos analisar os processos diante de suas variáveis:
          pessoas, riscos e controles internos, lógica do fluxo do processo,
          sistemas de informação e indicadores de desempenho para sugerir
          melhorias. Será criada a nova versão de processo.
        </>
      ),
    },
    {
      count: 4,
      title: 'Implementação',
      description: (
        <>
          Muito importante para a melhoria não ficar apenas na teoria. A Valore
          utiliza as técnicas de GMO do instituto HCMI para engajar e "puxar" as
          mudanças para a empresa realmente implantar o que foi aprovado. Com
          reuniões periódicas unem-se os agentes das mudanças (comitê) para
          reportarem as necessidades ou as dificuldades da implantação.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="Gestāo por processos de negócio BPM - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="
              Buscar a melhor solução para seu processo, seja apenas uma padronização ou ainda mudanças mais sofisticadas como RPA e IA."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default Bpm
